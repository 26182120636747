
























































import { Component, Vue } from "vue-property-decorator";
import CVEsModule from "@/store/modules/CVEs";
import SpinnerCustom from "@/components/SpinnerCustom.vue";
import { CVE } from '@/store/models/CVE';
import { mdiOpenInNew } from '@mdi/js';

@Component({
  components: {
    SpinnerCustom,
  }
})
export default class CVEDashboard extends Vue {
  private loading: boolean = false;
  private mdiOpenInNew = mdiOpenInNew;
  @CVEsModule.namespace.State("CVEs") private CVEs: CVE[];

  search = '';
  sortBy = ['id'];
  sortDesc = [true];
  headers = [
    { text: 'ID', value: 'id' },
    { text: 'Title', value: 'title' },
    { text: 'Issued Date', value: 'issued_date', align: 'start' },
  ];

  private async mounted() {
    try {
      this.loading = true;
      await this.$store.dispatch('CVEs/getCVEs', ({}));
      this.loading = false
    }
    catch (e) {
      this.loading = false;
      console.log(e);
    }
  }

  /**
   * Filter to check if search includes CVE ID
   */
  private includesId(cve: CVE, searchTerm: string): boolean {
    const id = cve.id.toLowerCase().replace(/:/g, '-');
    return id.includes(searchTerm);
  }

  /**
   * Filter to check if search includes the CVE Title
   */
  private includesTitle(cve: CVE, searchTerm: string): boolean {
    return cve.title.toLowerCase().includes(searchTerm);
  }

  /**
   * Filter to check if search includes CVE Description
   */
  private includesDescription(cve: CVE, searchTerm: string): boolean {
    if (!cve.description) return true;
    return cve.description.toLowerCase().includes(searchTerm);
  }

  /**
   * Filter to check if search includes CVE References
   */
  private includesReferences(cve: CVE, searchTerm: string): boolean {
    return cve.references && cve.references.some(ref => {
      const refId = ref.id.toLowerCase().replace(/:/g, '- ');
      return refId.includes(searchTerm)
    });
  }

  /**
   * Getter that bind to the table and apply search data functionality
   */
  get filteredCVEs() {
    const searchTerm = this.search.toLowerCase().replace(/:/g, '-');
    return this.CVEs.filter(cve =>
        this.includesId(cve, searchTerm) ||
        this.includesTitle(cve, searchTerm) ||
        this.includesDescription(cve, searchTerm) ||
        this.includesReferences(cve, searchTerm)
    );
  }

  getReferenceUrlById(item) {
    const reference = item.references.find(ref => ref.id === item.id);
    return reference ? reference.href : '#';
  }

  timestampToDate(timestamp) {
    const date = new Date(timestamp * 1000);
    const padWithZero = (number) => number < 10 ? `0${number}` : number;
    return `${date.getFullYear()}.${padWithZero(date.getMonth() + 1)}.${padWithZero(date.getDate())}`;
  }
}
