/* eslint-disable */
import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import { Version } from "@/store/models/Version";
import axios from "axios";
import { BindingHelpers } from "vuex-class/lib/bindings";
import { namespace } from "vuex-class";

export const MODULE_NAME = "versions";
export const getExamNamespace = (): BindingHelpers => namespace(MODULE_NAME);
@Module({ stateFactory: true, namespaced: true })
export default class PostsModule extends VuexModule {
  private static _namespace: BindingHelpers;

  public static get namespace(): BindingHelpers {
    if (this._namespace == null) this._namespace = getExamNamespace();

    return this._namespace;
  }

  public versions: Version[] = [];

  @Action
  public async getVersions({ type, offset, clear, query }) {
    let res;
    let url = `/api/pack/${type}?offset=${offset ||
      0}`;
    if (type === "search") {
      url = `/api/search?query=${query}&offset=${offset ||
        0}`;
    }
    res = await axios({ method: "get", url });

    // Regular request and search request has different structure
    if (type === "search") {
      res.data.sort((a: any, b: any) => {
        const idA: string = a.id.split('-').slice(1).join('-');
        const idB: string = b.id.split('-').slice(1).join('-');

        return compareVersions(idA, idB);
      });
    } else {
      res.data.rules.sort((a: any, b: any) => {
        const idA: string = a.id.split('-').slice(1).join('-');
        const idB: string = b.id.split('-').slice(1).join('-');

        return compareVersions(idA, idB);
      });
    }

    this.context.commit("setVersions", { data: res.data, clear, type });
    return res.data;
  }

  @Mutation
  public setVersions(value) {
    if (value.clear) {
      this.versions = value.type === "search" ? value.data : value.data.rules;
    } else {
      const res = value.type === "search" ? value.data : value.data.rules;
      this.versions = [...this.versions, ...res];
    }
  }
}

const compareVersions = function (a: string, b: string): number {
  const regex: RegExp = /(\d+(?:\.\d+)*-\d+(?:\.\d+)*)/;
  const matchA = a.match(regex);
  const matchB = b.match(regex);

  if (!matchA || !matchB) {
    return 0; // unable to compare
  }

  const splitVersion = (version: string) => version.split(/[-.]/).map(Number);

  const versionA = splitVersion(matchA[1]);
  const versionB = splitVersion(matchB[1]);

  for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
    const segmentA = i < versionA.length ? versionA[i] : 0;
    const segmentB = i < versionB.length ? versionB[i] : 0;

    if (segmentA !== segmentB) {
      return segmentB - segmentA; // Invert the comparison for descending order
    }
  }

  return 0;
}