























































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import {
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanelContent,
  VCol,
  VRow,
  VTooltip,
  VIcon,
  VBtn,
  VCard
} from "vuetify/lib";
import VersionsModule from "@/store/modules/versions";
import { Version } from "@/store/models/Version";
import SpinnerCustom from "@/components/SpinnerCustom.vue";
import { mdiContentCopy, mdiCloseCircle, mdiInformation } from "@mdi/js";
@Component({
  components: {
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelHeader,
    VCol,
    VRow,
    VExpansionPanelContent,
    VTooltip,
    VIcon,
    SpinnerCustom,
    VBtn,
    VCard
  }
})
export default class Changelog extends Vue {
  private loading: boolean = false;

  @VersionsModule.namespace.State("versions") private versions: Version[];

  private panel: number[] = [];
  private snackbar: boolean = false;
  private mdiContentCopy = mdiContentCopy;
  private mdiCloseCircle = mdiCloseCircle;
  private mdiInformation = mdiInformation;
  private count: number = 0;
  private clicked: number = 0;
  private openedIndex: number = 0;
  private command: string = "";

  private closeWindow() {
    if (this.command) this.command = "";
  }

  private hightlight(filter, str) {
    if (!str) return "There is no changelog for this package version.";
    if (!filter) return str;
    const regexp = new RegExp(filter, "ig");
    const matchValue = str.match(regexp);
    if (matchValue) {
      const strRes = str
        .split(regexp)
        .map((s, index, array) => {
          if (index < array.length - 1) {
            const c = matchValue.shift();
            return `${s}<span class="hightlight">${c}</span>`;
          }
          return s;
        })
        .join("");
      return strRes;
    }
    return str;
  }

  private openModal(event, index, item) {
    event.stopPropagation();
    this.openedIndex = index;
    if (item.additional_info) {
      this.command = "additional_info";
      return;
    }
    this.command = `yum update `;
    if (
      item.id.indexOf("alt-node") !== -1 ||
      (item.id.indexOf("alt-python") !== -1 &&
        item.id.indexOf("-cllib") === -1 &&
        item.id.indexOf("-virtualenv") === -1) ||
      item.id.indexOf("ea-php") !== -1 ||
      (item.id.indexOf("alt-php") !== -1 &&
        item.id.indexOf("-pecl-ext") === -1 &&
        item.id.indexOf("alt-php-ssa") === -1 &&
        item.id.indexOf("ImageMagick") === -1 &&
        item.id.indexOf("-php-config") === -1 &&
        item.id.indexOf("alt-php-xray") === -1)
    ) {
      this.command = `yum groupupdate `;
    }
    const star =
      (item.id.indexOf("alt-php") !== -1 &&
        this.command === `yum groupupdate `) ||
      item.id.indexOf("cl-MariaDB") !== -1 ||
      item.id.indexOf("cl-MySQL") !== -1
        ? "*"
        : "";
    let forBeta = " --enablerepo=cloudlinux-updates-testing";
    if (item.id.indexOf("ea-apache24-mod_lsapi") !== -1) forBeta = "--enablerepo=cloudlinux-updates-testing,cl-ea4-testing"
    // RT-74 : FIX update command for ea-openssl11 packages
    if (item.id.indexOf("ea-openssl11") !== -1) forBeta = " --enablerepo=cl-ea4-testing"
    const forRollout = ` --enablerepo=cloudlinux${
      item.id.indexOf("ea-") !== -1 ? "-ea4-" : "-"
    }rollout-${item.slot}-bypass`;
    const additionalInfo =
      item.state === "beta"
        ? forBeta
        : item.state === "rollout"
        ? forRollout
        : "";

    if (item.id.indexOf("cloudlinux-release") !== -1) {
      this.command = this.command + "cloudlinux-release" + additionalInfo;
      return;
    }
    if (item.id.indexOf("kernel") !== -1) {
      this.command = this.command + "kernel" + additionalInfo;
      return;
    }
    if (item.id.indexOf("rhn-client-tools") !== -1) {
      this.command = this.command + "rhn-client-tools" + additionalInfo;
      return;
    }
    // STAR-101 : FIX update command for alt-node packages
    if (item.id.indexOf("alt-node") !== -1 && this.command === `yum groupupdate `) {
      const packIndex = item.id.match(/-\d/);
      let cmd = `${this.$route.params.package}`;
      const firstHyphenIndex = cmd.indexOf('-');
      const secondHyphenIndex = cmd.indexOf('-', firstHyphenIndex + 1);
      if (secondHyphenIndex !== -1) {
        cmd = cmd.slice(0, secondHyphenIndex) + cmd.slice(secondHyphenIndex + 1);
      }
      this.command =
        this.command +
        cmd +
        star +
        additionalInfo;
      return;
    }
    if (this.$route.query.value) {
      const packIndex = item.id.match(/-\d/);
      this.command =
        this.command +
        `${item.id.substr(0, packIndex.index)}` +
        star +
        additionalInfo;
      return;
    }
    this.command =
      this.command + `${this.$route.params.package}` + star + additionalInfo;
  }

  private copyVal(textToCopy) {
    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(textToCopy);
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  }

  private copy(event, text) {
    event.stopPropagation();
    this.copyVal(`${text}`);
    this.snackbar = true;
  }

  private async loadMore() {
    if (this.count > this.versions.length) {
      await this.$store.dispatch('versions/getVersions', ({
        type: this.$route.params.package,
        offset: this.versions.length,
        clear: false,
        query: ""
      }));
    }
  }

  @Watch("$route")
  private async paramChanged() {
    this.loading = true;
    const data = await this.$store.dispatch('versions/getVersions', ({
      type: this.$route.params.package,
      offset: 0,
      clear: true,
      query: this.$route.query?.value || ""
    }));
    this.count = data.count;
    this.command = "";
    this.panel = [0, 1, 2, 3, 4, 5];
    this.loading = false;
  }

  private async mounted() {
    this.loading = true;
    if (this.$route.params.package) {
      const data = await this.$store.dispatch('versions/getVersions', ({
        type: this.$route.params.package,
        offset: 0,
        clear: true,
        query: this.$route.query?.value || ""
      }));
      this.count = data.count;
    }
    this.panel = [0, 1, 2, 3, 4, 5];
    this.loading = false;
  }
}
