import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Changelog from "../views/Changelog.vue";
import packages from "@/utils/variables";
import CVEDashboard from "@/views/CVEDashboard.vue";

Vue.use(VueRouter);

const allRoutes: string[] = ['search']
const mapAll = (childrenArr) => {
  childrenArr.map(el => {
    if (el.route) {
      allRoutes.push(el.route)
    }
    if (el.children) mapAll(el.children)
  })
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "cve",
        name: "cve",
        components: { cve: CVEDashboard },
      },
      {
        path: "/:package",
        name: "changelog",
        alias: "/:package/",
        components: { version: Changelog },
        beforeEnter: (to, from, next) => {
          mapAll(packages)
          return allRoutes.includes(to.params.package)
            ? next()
            : next({ name: "Home" });
        },
        props: { version: true },
      },
      {
        path: "/:package(.*)*",
        redirect: (to) => {
          mapAll(packages)
          if (allRoutes.includes(to.params.package[0])) {
            return {
              name: "changelog",
              params: { package: to.params.package[0] },
            };
          } else {
            return {
              name: "Home",
            };
          }
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
