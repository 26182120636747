<template>
  <div class="spinner-container">
    <v-img
      class="spinner"
      src="./../assets/CloudLinux.png"
      max-width="150"
    ></v-img>
  </div>
</template>

<script>
export default {
  name: 'SpinnerCustom',
};
</script>

<style scoped lang="scss">
$spinner-color: cornflowerblue;
$spinner-size: 32px;
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinner {
  animation: spin 2s ease-in-out infinite;
}
.spinner-container {
  z-index: 100;
  top: 50%;
  left: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  cursor: wait;
  right: 0;
  width: 150px;
  position: absolute;
  transform: translate(-50%, -50%);
  height: 150px;
}
</style>
