





















import {Vue, Component, Watch} from 'vue-property-decorator';
import {VTab, VTreeview, VBtn} from 'vuetify/lib';
import packages from './../utils/variables';

@Component({
  components: {
    VTab,
    VTreeview,
    VBtn,
  },
})
export default class Navigation extends Vue {
  private packages: Array<object> = packages;
  activeRoute: string = 'none';

  @Watch('$route', {immediate: true, deep: true})
  onUrlChange(newVal: any) {
    this.activeRoute = newVal.params.package;
    if (!newVal.params.package) {
      this.activeRoute = 'none';
    }
  }

  private async openDialog(item) {
    if (item.versions) {
      this.$emit('clear');
      this.activeRoute = item.route;
      this.$router.push({name: 'changelog', params: {package: item.route}});
    }
    if (item.route === 'cve') {
      this.$emit('clear');
      this.activeRoute = item.route;
      this.$router.push({ name: 'cve',  params: {package: item.route}});
    }
  }
}
