<template>
  <div class="info-block">
    <div>
      <h3>CloudLinux OS and components changelog website.</h3>
      Here you can find the description of existing CloudLinux OS components:
    </div>
    <ul>
      <li>
        <p><strong>LVE Manager</strong></p>
        <p>
          LVE is a Lightweight Virtualized Environment. LVE Manager allows you
          to maintain fine-tuned control over your resources, including CPU, IO
          rate, memory, inodes, numbers of processes, and concurrent
          connections, that any single account can use. Now you can limit
          resource abuse, while allowing good customers to use what they need.
        </p>
        <p><a href="https://docs.cloudlinux.com/lve_manager/">See more</a>.</p>
      </li>
      <li>
        <p><strong>CageFS</strong></p>
        <p>
          CageFS is a virtualized, per-user file system that uniquely
          encapsulates each customer, preventing users from seeing each other
          and viewing sensitive information. CageFS prevents a large number of
          attacks, including most privilege escalation and information
          disclosure attacks. It is completely transparent to your customers,
          without any need for them to change their scripts.
        </p>
        <p>
          <a href="https://docs.cloudlinux.com/cloudlinux_os_components/#cagefs"
            >See more</a
          >.
        </p>
      </li>
      <li>
        <p><strong>MySQL Governor</strong></p>
        <p>
          MySQL Governor tracks CPU and disk IO usage for every user in real
          time and throttles MySQL queries by using LVE limits. By using the
          <a href="https://docs.cloudlinux.com/command-line_tools/#dbtop"
            >dbtop</a
          >
          utility, it is possible to see the database usage on a per-customer
          basis, ensuring that the system admin always know what is going on.
        </p>
        <p>
          <a
            href="https://docs.cloudlinux.com/cloudlinux_os_components/#mysql-governor"
            >See more</a
          >.
        </p>
      </li>
      <li>
        <p><strong>PHP Selector</strong></p>
        <p>
          PHP Selector allows end users to select the specific version of PHP
          they need. It allows ultimate flexibility by offering all popular
          versions of PHP, with more than 120 PHP extensions to choose from.
        </p>
        <p>
          <a
            href="https://docs.cloudlinux.com/cloudlinux_os_components/#php-selector"
            >See more</a
          >.
        </p>
      </li>
      <li>
        <p><strong>Ruby Selector</strong></p>
        <p>
          Ruby Selector allows end users to choose the Ruby version for
          applications and install additional modules to the application
          environment. Ruby Selector uses <code>mod_passenger</code> for
          delivering optimum performance.
        </p>
        <p>
          <a
            href="https://docs.cloudlinux.com/cloudlinux_os_components/#ruby-selector"
            >See more</a
          >.
        </p>
      </li>
      <li>
        <p><strong>Python Selector</strong></p>
        <p>
          Python Selector allows end users to choose the Python version for
          applications and install additional modules. Python Selector uses
          <code>mod_passenger</code> to get the best performance from Python
          applications.
        </p>
        <p>
          <a
            href="https://docs.cloudlinux.com/cloudlinux_os_components/#python-selector"
            >See more</a
          >.
        </p>
      </li>
      <li>
        <p>
          <span class="notranslate"><strong>Node.js Selector</strong></span>
        </p>
        <p>
          Node.js Selector is a CloudLinux component that allows each user to
          easily create Node.js applications, choose Node.js version and other
          parameters for applications based on their needs.
        </p>
        <p>
          <a
            href="https://docs.cloudlinux.com/cloudlinux_os_components/#node-js-selector"
            >See more</a
          >.
        </p>
      </li>
      <li>
        <p><strong>Apache mod_lsapi PRO</strong></p>
        <p>
          Mod_lsapi PRO is the fastest PHP handler for Apache. It is a drop-in
          replacement for SuPHP, FCGID, RUID2, and ITK. It has a low memory
          footprint and understands
          <code>.htaccess</code> PHP directives.
        </p>
        <p>
          <a
            href="https://docs.cloudlinux.com/cloudlinux_os_components/#apache-mod-lsapi-pro"
            >See more</a
          >.
        </p>
      </li>
      <li>
        <p>
          <span class="notranslate"><strong>LVE-stats 2</strong></span>
        </p>
        <p>
          <span class="notranslate"> LVE-stats 2 </span> collects LVE usage
          statistics (CPU, memory, disk space usage) and allows to collect the
          usage data.
        </p>
        <p>
          <a
            href="https://docs.cloudlinux.com/cloudlinux_os_components/#lve-stats-2"
            >See more</a
          >.
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DescriptionInfo"
};
</script>

<style scoped>
.info-block {
  padding: 20px;
  width: 100%;
  height: 100%;
}
</style>
