<template>
  <footer class="footer">
    <div>
      <a href="https://cloudlinux.com"
        ><img
          class="d-block"
          src="./../assets/we-are-cloudlinux.svg"
          alt="We are Cloudlinux"
      /></a>
    </div>
    <div class="footer-company-title">
      © 2022 - {{new Date().getFullYear()}} All rights reserved. CloudLinux Inc. <br /><a
        href="https://www.cloudlinux.com/terms-of-use"
        target="_blank"
        >Terms of Use</a
      >
      |
      <a href="https://www.cloudlinux.com/privacy-policy" target="_blank"
        >Privacy Policy</a
      >
      |
      <a
        href="https://www.cloudlinux.com/vulnerability-reporting"
        target="_blank"
        >Vulnerability Reporting</a
      >
      |
      <a href="https://www.cloudlinux.com/legal" target="_blank">Legal</a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterCustom"
};
</script>

<style scoped>
.footer a {
  display: inline-block;
  color: #2b98f0 !important;
  font-size: 0.8rem;
  font-weight: 500;
}
.footer {
  color: #314659;
  text-align: center;
  border-top: 1px solid #e8e8e8;
  height: 102px;
  background: #fff;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
}
.footer > div {
  margin: 5px;
}
.footer-company-title {
  font-size: 0.8rem;
  display: block;
  color: #d8d8d8;
}
.footer-company-title > a {
  color: #2a97f4;
}
</style>
