import Vue from "vue";
import Vuex from "vuex";
import versions from "./modules/versions";
import CVEs from "./modules/CVEs";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    versions,
    CVEs
  }
});
