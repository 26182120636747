var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cve-dashboard"},[(_vm.loading)?_c('spinner-custom'):_vm._e(),_c('v-card',[_c('v-card-title',[_vm._v(" CVE Dashboard "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredCVEs,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"item-key":"id","show-expand":"","single-expand":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"cve-id-link",attrs:{"href":_vm.getReferenceUrlById(item),"target":"_blank"}},[_c('v-icon',{staticClass:"cve-id-link-icon",attrs:{"size":"16"}},[_vm._v(" "+_vm._s(_vm.mdiOpenInNew)+" ")]),_vm._v(" "+_vm._s(item.id)+" ")],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"v-data-table__expanded__content",attrs:{"colspan":headers.length}},[_c('strong',[_vm._v("Description:")]),_c('pre',{staticClass:"cve-description"},[_vm._v(_vm._s(item.description))]),_c('br'),_c('div',[_c('strong',[_vm._v("References:")]),_c('ul',_vm._l((item.references),function(ref){return _c('li',{key:ref.id},[_c('a',{staticClass:"cve-reference",attrs:{"href":ref.href,"target":"_blank"}},[_vm._v(_vm._s(ref.id))])])}),0)])])]}},{key:"item.issued_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.timestampToDate(item.issued_date))+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }