



























































import { Component, Vue } from "vue-property-decorator";
import debounce from "lodash/debounce";
import Navigation from "@/components/Navigation.vue";
import FooterCustom from "@/components/FooterCustom.vue";
import DescriptionInfo from "@/components/DescriptionInfo.vue";
import { mdiLifebuoy, mdiInformation, mdiRss } from "@mdi/js";

@Component({
  components: {
    Navigation,
    FooterCustom,
    DescriptionInfo
  }
})
export default class Home extends Vue {
  public search: string = "";
  private mdiLifebuoy = mdiLifebuoy;
  private mdiInformation = mdiInformation;
  private mdiRss = mdiRss;
  private static scrollTop(): void {
    document.getElementsByTagName("html")[0]!.scrollTop = 0;
  }

  get isRootRoute() {
    return this.$route.path === '/';
  }

  get isCveRoute() {
    return this.$route.name === 'cve';
  }

  private mounted() {
    if (this.$route.query?.value) {
      this.search =
        typeof this.$route.query?.value === "string"
          ? this.$route.query.value
          : "";
    }
  }

  private debounceSearch = debounce(event => {
    this.loadSearch(event.target.value);
  }, 1500);

  private loadSearch(value): void {
    this.search = value.trim();
    if (this.search) {
      this.$router.push(`/search?value=${this.search}`);
    } else {
      this.$router.push({ name: "Home" });
    }
  }

  private clear(): void {
    this.search = "";
  }

  scrollTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
