/* eslint-disable */
import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import axios from "axios";
import { BindingHelpers } from "vuex-class/lib/bindings";
import { namespace } from "vuex-class";
import { CVE } from "@/store/models/CVE";

export const MODULE_NAME = "CVEs";
export const getExamNamespace = (): BindingHelpers => namespace(MODULE_NAME);
@Module({ stateFactory: true, namespaced: true })
export default class PostsModule extends VuexModule {
  private static _namespace: BindingHelpers;

  public static get namespace(): BindingHelpers {
    if (this._namespace == null) this._namespace = getExamNamespace();

    return this._namespace;
  }

  public CVEs: CVE[] = [];

  @Action
  public async getCVEs() {
    let url = "/api/cve-list";
    let res = await axios({ method: "get", url });
    let parsedData: any = [];

    for (const item of res.data) {
      if (item.references && item.references.some(ref => ref.type === 'cve')) {
        const elementForAdding = {
          id: item.id,
          title: item.title,
          issued_date: item.issued_date,
          description: item.description,
          references: item.references.filter(ref => ref.type === 'cve' || ref.type === 'self')
        };
        parsedData.push(elementForAdding);
      }
    }
    this.context.commit("setCVEs", { data: parsedData });
    return parsedData;
  }

  @Mutation
  public setCVEs(payload) {
    this.CVEs = payload.data;
  }
}